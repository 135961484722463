import { useStaticQuery, graphql } from "gatsby"

const PressImgs = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "press" } }
        sort: { fields: [relativePath], order: DESC }
      ) {
        nodes {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return allFile.nodes
}

export default PressImgs
