import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"


import usePressList from "../../hooks/use-press-list"
import usePressImgs from "../../hooks/use-press-image"
import getArrayObjConcat from "../../utils/get-arrayObject-concat"
import PictureGrid from "../../utils/get-pictureGrid"
import Tab from "../../components/history/tabs"

import "./css/press.styl"

const Press = ({ location }) => {
  const intl = useIntl()
  const language = intl.locale
  const images = usePressImgs()
  const texts = usePressList()
  const pressList = getArrayObjConcat(images, texts)



  return (
    <Layout location={location}>
      <SEO
        location={location}
        lang={language}
        title={language !== "ja" ? "Press" : "掲載歴"}
        keywords={[`press`, `掲載歴`, `放射線像`, `Autoradiograph`]}
        description={language !== 'ja' ? `Our Autoradiograph project have been appearing on media in many countries.` : `国内外におけるメディア掲載歴です。`}
      />
      <Tab location={location}>
        <section className="history press component">
          {!pressList ? (
            <p>
              Oops! You should insert same number of press texts and press
              images...
            </p>
          ) : (
              <PictureGrid items={pressList} />
            )}
        </section>
      </Tab>
    </Layout>
  )
}

export default Press
